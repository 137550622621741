import { AppBar, Body, PageLayout } from 'components/common';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
import animationData from '../../lotties/3d_clover.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { useEffect } from 'react';

const CloverSwipeScreen: React.FC = () => {
  // const rtl = BResource.getSelectedLocale() === 'ar';

  useEffect(() => {
    FindHerDataManager.sendLog('pageOpened', { curPage: Transition.getCurrentPage(), prevPage: Transition.getPrevHistoryName() }, { sendMyInfo: true });
  }, []);

  const listItem = (text: string) => {
    return <div className="flex flex-row items-center text-gray-500 text3">{text}</div>;
  };

  const backNSwipe = () => {
    Transition.resetToHome();
    setTimeout(() => {
      FindUI.swipe();
    }, 300);
  };

  return (
    <PageLayout backgroundColor="bg-black">
      <AppBar className="text-white bg-black text-title4" trailings={<Icon name="cafe/close.svg" className="m-3" onClick={() => Transition.back()} />} />
      <Body
        onClose={() => Transition.back()}
        className="flex flex-col items-center"
        backgroundColor="#000"
        style={{
          color: '#FFF',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <div className="w-[70%] mt-6">
          <Player autoplay src={animationData} />
        </div>
        <div className="flex flex-col w-full px-5 gap-2 text-center items-center">
          <div className="">{brsc.f_title}</div>
          {listItem(brsc.f_detail_1)}
          {listItem(brsc.f_detail_2)}
          {listItem(brsc.m_detail_7)}
        </div>
        <div className="flex grow"></div>
        <div style={{ minHeight: '300px', width: '100%', backgroundColor: 'black', display: 'flex' }} />
        <div className="fixed bottom-10 w-full px-4 bg-black">
          <Button className="w-full" style={{ text: brsc.btn_region_popup, type: 'solid' }} onClick={backNSwipe} />
          <Button className="w-full" style={{ text: brsc.later, type: 'text', fontColor: 'gray-700' }} onClick={() => Transition.back()} />
        </div>
        <div className="flex grow"></div>
      </Body>
    </PageLayout>
  );
};

export default CloverSwipeScreen;
