import { AppBar, Body, Button, Icon, PageLayout } from 'components/common';
import { InfiniteHorizontalScroll } from 'components/view/SlidingImage';
import { useEffect } from 'react';

const FindLuckyStarScreen: React.FC = () => {
  useEffect(() => {
    FindHerDataManager.sendLog('pageOpened', { curPage: Transition.getCurrentPage(), prevPage: Transition.getPrevHistoryName() }, { sendMyInfo: true });
  }, []);

  const backNSwipe = () => {
    Transition.resetToHome();
    setTimeout(async () => {
      await FindHerDataManager.startFindingHer();
      FindUI.swipe();
    }, 300);
  };

  const listItem = (text: string) => {
    return (
      <div className="flex flex-row items-center gap-2 text4" style={{ color: 'rgba(255,255,255,0.7)' }}>
        <div className="w-1 h-1 rounded-full bg-gray-700 shrink-0" />
        {text}
      </div>
    );
  };

  return (
    <PageLayout backgroundColor="bg-black">
      <AppBar className="text-white bg-black text-title4" trailings={<Icon name="cafe/close.svg" className="m-3" onClick={() => Transition.back()} />} />
      <Body
        onClose={() => Transition.back()}
        className="flex flex-col items-center"
        backgroundColor="#000"
        style={{
          color: '#FFF',
          transition: 'all 0.3s ease-in-out',
          overflowX: 'hidden',
          width: '100%',
        }}
      >
        <div className="flex flex-col h-[320px] min-h-[320px] w-full bg-black gap-2">
          <InfiniteHorizontalScroll imageUrl={'/imgweb/find_her/slide01.jpg'} height={160} direction="left" gap={8} />
          <InfiniteHorizontalScroll imageUrl={'/imgweb/find_her/slide02.jpg'} height={160} direction="right" gap={8} />
        </div>
        <div className="flex flex-col items-center px-4 mt-8">
          <div className="text-title2">💝Find the Lucky Star💝</div>
          <div className="text2 text-center mt-2" style={{ color: 'rgba(255,255,255,0.7)' }}>
            {brsc.m_detail_3}
          </div>
        </div>
        <div className="flex flex-col w-full p-6 gap-4">
          {listItem(brsc.m_detail_4)}
          {listItem(brsc.m_detail_5)}
          {listItem(brsc.m_detail_6)}
          {listItem(brsc.m_detail_7)}
        </div>
        <div style={{ minHeight: '150px', width: '100%', backgroundColor: 'black', display: 'flex' }} />
        <div className="fixed bottom-0 pb-10 w-full px-4 pt-8" style={{ background: 'linear-gradient(0deg, #000 74.79%, rgba(0, 0, 0, 0.00) 100%)' }}>
          <Button
            className="w-full"
            style={{
              text: brsc.btn_region_popup,
              type: 'solid',
            }}
            onClick={backNSwipe}
          />
          <Button className="w-full" style={{ text: brsc.later, type: 'text', fontColor: 'gray-700' }} onClick={() => Transition.back()} />
        </div>
        <div className="flex grow" />
        <div className="flex min-h-[50px]" />
      </Body>
    </PageLayout>
  );
};

export default FindLuckyStarScreen;
