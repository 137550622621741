import { useEffect, useRef, useState } from 'react';

type ScrollDirection = 'left' | 'right';

interface InfiniteScrollProps {
  imageUrl: string;
  height?: number;
  speed?: number;
  direction?: ScrollDirection;
  gap?: number;
}

const InfiniteHorizontalScroll: React.FC<InfiniteScrollProps> = ({ imageUrl = '', height = 100, speed = 50, direction = 'left', gap = 0 }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [images, setImages] = useState<string[]>([]);
  const [imageWidth, setImageWidth] = useState<number>(0);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const calculatedWidth = (img.width / img.height) * height;
      setImageWidth(calculatedWidth);

      setImages([imageUrl, imageUrl, imageUrl, imageUrl]);
    };
  }, [imageUrl, height]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || imageWidth === 0) return;

    let animationFrameId: number;
    let xPosition = 0;

    const animate = () => {
      const totalImageWidth = imageWidth + gap;

      xPosition += direction === 'left' ? -1 : 1;

      const shouldReplace = direction === 'left' ? Math.abs(xPosition) >= totalImageWidth : xPosition >= 0;

      if (shouldReplace) {
        setImages((prevImages) => {
          return direction === 'left' ? [...prevImages.slice(1), imageUrl] : [imageUrl, ...prevImages.slice(0, -1)];
        });

        xPosition = direction === 'left' ? 0 : -totalImageWidth;
      }

      if (container.firstChild) {
        (container.firstChild as HTMLDivElement).style.transform = `translateX(${xPosition}px)`;
      }

      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [imageWidth, imageUrl, direction, gap]);

  return (
    <div ref={containerRef} className="overflow-hidden relative w-full" style={{ height: `${height}px` }}>
      <div className="flex absolute top-0 left-0">
        {images.map((src, index) => (
          <div
            key={index}
            className="flex items-center"
            style={{
              marginRight: index < images.length - 1 ? `${gap}px` : 0,
            }}
          >
            <img src={src} alt="" width={imageWidth} height={height} className="object-cover flex-shrink-0" />
          </div>
        ))}
      </div>
    </div>
  );
};

export { InfiniteHorizontalScroll };
